import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { coordinatesGenerator } from '../helpers/map';
import { GoogleApiWrapper, Map, Polyline, Marker } from 'google-maps-react';
import markerIcon from '../assets/images/marker.svg';

const useStyles = makeStyles({
  mapField: {
    position: 'relative',
    width: '100%',
    height: '400px',
  },
});

const GoogleMap = (props) => {
  const { google, record } = props;
  const { wells } = record;
  const [coordsId, setCoordsId] = useState('');

  useEffect(() => {
    const timer = setTimeout(() => {
      const coordsId = wells
        .map((well) =>
          well
            ? `${well.surfaceLatitude},${well.surfaceLongitude};${well.bottomLatitude},${well.bottomLongitude}`
            : ''
        )
        .join('|');
      setCoordsId(coordsId);
    }, 1000);
    return () => clearTimeout(timer);
  }, [wells]);

  const { polylinesCoordinates, markersCoordinates } = useMemo(() => {
    return coordinatesGenerator(wells);
    // eslint-disable-next-line
  }, [coordsId]);

  const [map, setMap] = useState();
  const classes = useStyles();

  const getMap = useCallback((_, map) => setMap(map), [setMap]);

  const initialCenter = useMemo(
    () => ({ lat: 40.134701, lng: -102.452763 }),
    []
  );

  const useBoundsFromMarkers = true;

  const renderPolylines = useCallback(() => {
    if (!useBoundsFromMarkers && map && polylinesCoordinates.length > 0) {
      const bounds = new google.maps.LatLngBounds();

      polylinesCoordinates.forEach((polyline) => {
        polyline.forEach((marker) => {
          bounds.extend(marker);
        });
      });

      map.fitBounds(bounds);
    }

    return polylinesCoordinates.map((path, index) => (
      <Polyline
        key={index}
        path={path}
        strokeColor="#100081"
        strokeOpacity={1.0}
        strokeWeight={2}
      />
    ));
  }, [
    google.maps.LatLngBounds,
    map,
    polylinesCoordinates,
    useBoundsFromMarkers,
  ]);

  const renderMarkers = useCallback(() => {
    if (useBoundsFromMarkers && map && markersCoordinates.length > 0) {
      const bounds = new google.maps.LatLngBounds();

      markersCoordinates.forEach((marker) => {
        bounds.extend(marker);
      });

      map.fitBounds(bounds);
    }

    return markersCoordinates.map((coordinates, index) => (
      <Marker key={index} position={coordinates} icon={markerIcon} />
    ));
  }, [google.maps.LatLngBounds, map, markersCoordinates, useBoundsFromMarkers]);

  const polylines = useMemo(() => renderPolylines(), [renderPolylines]);
  const markers = useMemo(() => renderMarkers(), [renderMarkers]);

  return (
    <div className={classes.mapField}>
      <Map
        google={google}
        initialCenter={initialCenter}
        zoom={3}
        onReady={getMap}
      >
        {polylines}
        {markers}
      </Map>
    </div>
  );
};

GoogleMap.propTypes = {
  wells: PropTypes.objectOf(PropTypes.array),
};

export default compose(
  GoogleApiWrapper({
    apiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    language: 'us',
  })
)(GoogleMap);
