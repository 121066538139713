import { apiAuthTokenQueryParamName } from 'now-shared/helpers/auth-helpers';
import { generateFetchOptions } from '../helpers/fetch';
import {
  getAuthTokenQueryParam,
  getUserData,
  storeAuthToken,
  clearUserData,
  storeUserData,
  getAuthTokenStored,
  getOtpToken,
  storeOTPtoken,
} from '../auth/auth-helpers';
import { getUserFullName } from 'now-shared/helpers/user-helpers';

export const UserRoles = {
  Guest: 'guest',
  Admin: 'admin',
  SuperAdmin: 'superAdmin',
  ComplianceSupervisor: 'complianceSupervisor',
  RegisteredRepresentative: 'registeredRepresentative',
  CompliancePerson: 'compliancePerson',
};

let checkAuthPromise;
let refreshUserDataPromise;
let hasUpdatedUserDataThisPageLoad = false;

async function updateUserDataFromLoginResponse(response) {
  const json = await response.json();
  if (json.statusCode < 200 || json.statusCode >= 300) {
    throw new Error(json.message);
  } else {
    const {
      id,
      accessToken,
      email,
      firstName,
      lastName,
      isAdmin,
      isSuperAdmin,
      isComplianceSupervisor,
      isRegisteredRepresentative,
    } = json;
    storeAuthToken(accessToken);
    storeUserData({
      id,
      email,
      firstName,
      lastName,
      isAdmin,
      isSuperAdmin,
      isComplianceSupervisor,
      isRegisteredRepresentative,
    });

    hasUpdatedUserDataThisPageLoad = true;
  }
}

export async function updateUserDataFromLoginDualFatcor(json) {
  const {
    id,
    accessToken,
    email,
    firstName,
    lastName,
    isAdmin,
    isSuperAdmin,
    isComplianceSupervisor,
    isRegisteredRepresentative,
  } = json;
  storeAuthToken(accessToken);
  storeUserData({
    id,
    email,
    firstName,
    lastName,
    isAdmin,
    isSuperAdmin,
    isComplianceSupervisor,
    isRegisteredRepresentative,
  });
}

async function storeOtpDetails(response) {
  const json = await response.json();
  if (json.statusCode < 200 || json.statusCode >= 300) {
    throw new Error(json.message);
  } else {
    const { otpToken } = json;
    storeOTPtoken(otpToken);
  }
}

export async function refreshUserData() {
  const doAsync = async () => {
    const options = {
      ...generateFetchOptions('GET'),
    };
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/me`,
      options
    );
    await updateUserDataFromLoginResponse(response);
  };

  let myPromise;
  if (!refreshUserDataPromise) {
    refreshUserDataPromise = doAsync();
    myPromise = refreshUserDataPromise;
  }

  try {
    await refreshUserDataPromise;
  } finally {
    if (refreshUserDataPromise === myPromise) {
      refreshUserDataPromise = undefined;
    }
  }
}

async function login({ username, password }) {
  const doAsync = async () => {
    const options = {
      ...generateFetchOptions('POST', {
        email: username,
        password,
      }),
    };
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/login`,
      options
    );
    await storeOtpDetails(response);
  };

  let myPromise;
  if (!refreshUserDataPromise) {
    refreshUserDataPromise = doAsync();
    myPromise = refreshUserDataPromise;
  }

  try {
    await refreshUserDataPromise;
  } finally {
    if (refreshUserDataPromise === myPromise) {
      refreshUserDataPromise = undefined;
    }
  }
}

async function verifyOtp({ code }) {
  const token = getOtpToken();
  const doAsync = async () => {
    const options = {
      ...generateFetchOptions('POST', {
        code,
        otpToken: token,
      }),
    };
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/verify`,
      options
    );
    await updateUserDataFromLoginResponse(response);
  };

  let myPromise;
  if (!refreshUserDataPromise) {
    refreshUserDataPromise = doAsync();
    myPromise = refreshUserDataPromise;
  }

  try {
    await refreshUserDataPromise;
  } finally {
    if (refreshUserDataPromise === myPromise) {
      refreshUserDataPromise = undefined;
    }
  }
}

const checkAuth = async () => {
  const doAsync = async () => {
    const location = window.location.href;
    const queryToken = getAuthTokenQueryParam(false);
    const storedToken = getAuthTokenStored();

    if (queryToken) {
      try {
        if (queryToken !== storedToken) {
          storeAuthToken(queryToken);
        }
        await refreshUserData();
      } finally {
        const url = new URL(location);
        url.searchParams.delete(apiAuthTokenQueryParamName);
        window.history.replaceState(null, null, url);
      }
    } else if (!storedToken) {
      const error = new Error();
      // go to login page
      throw error;
    } else if (!getUserData() || !hasUpdatedUserDataThisPageLoad) {
      await refreshUserData();
    }
  };

  let myPromise;
  if (!checkAuthPromise) {
    checkAuthPromise = doAsync();
    myPromise = checkAuthPromise;
  }

  try {
    await checkAuthPromise;
  } finally {
    if (checkAuthPromise === myPromise) {
      checkAuthPromise = undefined;
    }
  }
};

export const AuthProvider = {
  login,
  verifyOtp,
  logout: async () => {
    clearUserData();
  },
  checkError: async ({ status }) => {
    if (status === 401) {
      // UNAUTHORIZED
      clearUserData();
      const error = new Error();
      throw error;
    }
  },
  checkAuth: async () => {
    await checkAuth();
  },
  getIdentity: async () => {
    let result;

    try {
      await checkAuth();
    } catch (error) {
      // ignore error
    }

    const data = getUserData();
    if (data) {
      result = {
        ...data,
        fullName: getUserFullName(data)
          ? `${getUserFullName(data)} - ${data.email}`
          : data.email,
      };
    }
    return result;
  },
  getPermissions: async () => {
    // Don't throw an exception
    // https://github.com/marmelab/react-admin/issues/1605
    try {
      await checkAuth();
    } catch (error) {
      // ignore error
    }

    const userData = getUserData();

    const roles = [];

    if (!userData) {
      roles.push(UserRoles.Guest);
    } else {
      if (userData.isAdmin) {
        roles.push(UserRoles.Admin);
      }
      if (userData.isSuperAdmin) {
        roles.push(UserRoles.SuperAdmin);
      }
      if (userData.isComplianceSupervisor) {
        roles.push(UserRoles.ComplianceSupervisor);
      }
      if (userData.isRegisteredRepresentative) {
        roles.push(UserRoles.RegisteredRepresentative);
      }
      if (
        [
          UserRoles.ComplianceSupervisor,
          UserRoles.RegisteredRepresentative,
        ].includes(roles)
      ) {
        roles.push(UserRoles.CompliancePerson);
      }
    }
    return roles;
  },
};
